// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer {
  padding-top: 50px;
  padding-bottom: 40px;
  background-color: #303030;

  @media (min-width: 992px) {
    padding-top: 90px;
    padding-bottom: 60px;
  }
}

.footer__logo-desc {
  color: #8e8e8e;
  font-size: 14px;
  line-height: 1.3;
}

.footer_nav {
  margin-bottom: 15px;

  @media (min-width: 768px) {
    .collapse {
      display: block !important;
    }
  }
}

.footer-nav__title {
  display: flex;
  align-items: baseline;
  margin: 0;
  padding: .5rem 0;
  color: var(--c-white);
  font-size: 15px;
  font-weight: 700;

  .dropdown-caret {
    margin-right: 10px;
  }

  @media (min-width: 768px) {
    .icon {
      display: none;
    }
  }

  @media (min-width: 992px) {
    padding-top: 0;
    font-size: 20px;
  }
}

.nav--footer {
  --nav-link-padding-x: 1.5rem;
  --nav-link-bg-color: transparent;
  --nav-link-hover-bg-color: transparent;
  --nav-link-color: #bdbdbd;
  --nav-link-active-color: var(--c-red-secondary);
  --nav-link-hover-color: var(--c-red);
  --nav-link-font-size: 13px;
  --nav-link-line-height: 1.25;

  @media (min-width: 768px) {
    --nav-link-padding-x: 0;
    --nav-link-font-size: 1rem;
  }
}

.footer__copyright {
  background-color: #252525;
  color: #BDBDBD;
  font-size: 14px;
  padding: 40px 0;
}
