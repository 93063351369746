.page-404 {
  background-image: url(/dist/img/page404-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.page-404__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 0;
  margin-bottom: 5rem;
  background-image: url(/dist/img/page404-figure.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: var(--c-black);
  font-size: 1rem;

  @media (min-width: 1200px) {
    padding: 120px 0;
    font-size: 22px;
    margin-bottom: 10rem;
  }
}

.page-404__title {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--c-black);
  text-align: right;

  .strong {
    color: var(--c-red-secondary);
    font-size: 6rem;
    font-weight: 800;
    line-height: 1;
  }

  @media (min-width: 992px) {
    font-size: 2rem;

    .strong {
      font-size: 14rem;
      margin-bottom: -2rem;
    }
  }
}
