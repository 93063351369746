// TODO verify with bootstrap https://getbootstrap.com/docs/5.2/components/accordion/
// TODO add bootstrap js
// TODO requires collapse styles
// TODO focus to active accordion item's header

.accordion {
  // scss-docs-start accordion-css-vars
  --accordion-color: var(--c-body);
  --accordion-bg: #fff;
  --accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;;
  --accordion-border-color: var(--border-color);
  --accordion-border-width: var(--border-width);
  //--accordion-border-radius: #{$accordion-border-radius};
  //--accordion-inner-border-radius: #{$accordion-inner-border-radius};
  --accordion-btn-padding-x: 1rem;
  --accordion-btn-padding-y: 1.25rem;
  --accordion-btn-color: var(--c-body);
  --accordion-btn-bg: #fff;
  --accordion-btn-icon: ;
  --accordion-btn-icon-width: 1rem;
  --accordion-btn-icon-transform: rotate(135deg);
  --accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --accordion-btn-active-icon: ;
  //--accordion-btn-focus-border-color: #{$accordion-button-focus-border-color};
  //--accordion-btn-focus-box-shadow: #{$accordion-button-focus-box-shadow};
  --accordion-body-padding-x: 1.25rem;
  --accordion-body-padding-y: 1rem;
  --accordion-active-color: var(--c-primary-text);
  --accordion-active-bg: ;
  --accordion-hover-color: var(--c-primary-text);
  // scss-docs-end accordion-css-vars
  --accordion-btn-border-radius: 0;
  --accordion-btn-font-size: 1rem;
  --accordion-btn-font-weight: 400;

  //border-top: 1px solid rgba(0, 0, 0, 0.125);
  //border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--accordion-btn-padding-y) var(--accordion-btn-padding-x);
  color: var(--accordion-btn-color);
  font-size: var(--accordion-btn-font-size);
  font-weight: var(--accordion-btn-font-weight);
  text-align: left; // Reset button style
  background-color: var(--accordion-btn-bg);
  border: 0;
  border-radius: var(--accordion-btn-border-radius);
  overflow-anchor: none; // TODO what's this? test
  transition: (var(--accordion-transition));
  cursor: pointer;

  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none;
    }
  }

  // Active
  &:not(.collapsed) {
    color: var(--accordion-active-color);
    background-color: var(--accordion-active-bg);
    //box-shadow: inset 0 calc(-1 * var(--accordion-border-width)) 0 var(--accordion-border-color); // stylelint-disable-line function-disallowed-list

    &::after {
      //background-image: var(--accordion-btn-active-icon);
      transform: var(--accordion-btn-icon-transform);
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: var(--accordion-btn-icon-width);
    height: var(--accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--accordion-btn-icon-width);
    transition: var(--accordion-btn-icon-transition);
  }

  &:hover {
    color: var(--accordion-hover-color);
    z-index: 2;
  }

  /*&:focus {
    z-index: 3;
    border-color: var(--accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--accordion-btn-focus-box-shadow);
  }*/
}

.accordion-header {
  margin-top: 0;
  margin-bottom: 0;
}

.accordion-item {
  color: var(--accordion-color);
  background-color: var(--accordion-bg);
  border: var(--accordion-border-width) solid var(--accordion-border-color);

  /*&:first-of-type {
    @include border-top-radius(var(--accordion-border-radius));

    .accordion-button {
      @include border-top-radius(var(--accordion-inner-border-radius));
    }
  }*/

  &:not(:first-of-type) {
    border-top: 0;
  }

  // Only set a border-radius on the last item if the accordion is collapsed
  /*&:last-of-type {
    @include border-bottom-radius(var(--accordion-border-radius));

    .accordion-button {
      &.collapsed {
        @include border-bottom-radius(var(--accordion-inner-border-radius));
      }
    }

    .accordion-collapse {
      @include border-bottom-radius(var(--accordion-border-radius));
    }
  }*/
}

.accordion-body {
  padding: var(--accordion-body-padding-y) var(--accordion-body-padding-x);
}

/**
Theme styles
 */
.accordion--default {
  --accordion-btn-padding-y: 1rem;
  --accordion-btn-padding-x: 0;
  --accordion-btn-color: var(--c-grey);
  --accordion-active-color: var(--c-black);
  --accordion-btn-font-weight: 700;
  --card-spacer-x: 0;
  border-bottom: 1px solid var(--c-grey-light);

  .accordion-button {
    border-top: 1px solid var(--c-grey-light);

    &:not(.collapsed) {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  .icon {
    color: var(--c-primary) !important;
    margin-right: 10px;
  }

  .card-body {
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 1199px) {
    .tab-pane {
      //display: none;
      display: block !important;
      opacity: 1;
    }
  }

  @media (min-width: 1200px) {
    border-bottom: none;

    .collapse {
      display: block !important;
    }
  }
}







