// TODO
//
// Base styles
//

.card {
  // scss-docs-start card-css-vars
  --card-spacer-y: 1rem;
  --card-spacer-x: 1rem;
  //--card-title-spacer-y: #{$card-title-spacer-y};
  //--card-title-color: #{$card-title-color};
  //--card-subtitle-color: #{$card-subtitle-color};
  --card-border-width: 0;
  --card-border-color: transparent;
  --card-border-radius: 0;
  --card-box-shadow: ;
  --card-inner-border-radius: ;
  //--card-cap-padding-y: #{$card-cap-padding-y};
  //--card-cap-padding-x: #{$card-cap-padding-x};
  //--card-cap-bg: #{$card-cap-bg};
  //--card-cap-color: #{$card-cap-color};
  --card-height: 100%;
  --card-bg: var(--c-white);
  --card-color: (--c-text);
  --card-font-size: 1rem;
  --card-line-height: ;
  --card-title-mt: 0;
  --card-title-mb: ;
  --card-title-font-size: ;
  --card-title-line-height: ;
  --card-title-font-weight: ;
  //--card-img-overlay-padding: #{$card-img-overlay-padding};
  //--card-group-margin: #{$card-group-margin};
  // scss-docs-end card-css-vars

  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  width: 100%;
  height: var(--card-height); // todo
  color: var(--card-color);
  word-wrap: break-word;
  background-color: var(--card-bg);
  background-clip: border-box;
  border: var(--card-border-width) solid var(--card-border-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);

  &:hover {
    box-shadow: var(--card-hover-box-shadow);
  }

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .list-group {
    border-top: inherit;
    border-bottom: inherit;

    &:first-child {
      border-top-width: 0;
      border-top-left-radius: var(--card-inner-border-radius);
      border-top-right-radius: var(--card-inner-border-radius);
    }

    &:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: var(--card-inner-border-radius);
      border-bottom-left-radius: var(--card-inner-border-radius);
    }
  }

  // Due to specificity of the above selector (`.card > .list-group`), we must
  // use a child selector here to prevent double borders.
  > .card-header + .list-group,
  > .list-group + .card-footer {
    border-top: 0;
  }
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: var(--card-spacer-y) var(--card-spacer-x);
  color: var(--card-color);
}

.card-title {
  margin-top: var(--card-title-mt);
  margin-bottom: var(--card-title-mb);
  font-size: var(--card-title-font-size);
  line-height: var(--card-title-line-height);
  font-weight: var(--card-title-font-weight);

  a {
    font-size: inherit;
  }
}

.card-text {
  margin-top: 0;
  font-size: var(--card-font-size);
  line-height: var(--card-line-height);

  &:last-child {
    margin-bottom: 0;
  }

  &--line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

/**
*
*
*
 */
.card-subtitle {
  margin: 0;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-img {
  //TODO test different image sizes
  display: block;
  width: 100%;

  &--top,
  &--bottom {

  }
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// TODO: add card-header, card-footer

/**
Theme styles
 */
.card--default {
  --card-spacer-y: 1.5rem;
  --card-spacer-x: 1.25rem;
  --card-border-radius: 0.125rem;
  --card-font-size: .75rem;
  --card-line-height: 1.25;
  --card-title-mb: 1rem;
  --card-title-font-size: 14px;
  --card-title-line-height: 1.25;
  --card-title-font-weight: 700;

  .card-logo {
    //todo
    height: 50px;
    width: 100%;
    margin-bottom: 1rem;
  }

  @media (min-width: 992px) {
    --card-spacer-y: 2rem;
    --card-spacer-x: 2rem;
    --card-font-size: 1rem;
    --card-title-mb: 1.25rem;
    --card-title-font-size: 18px;

    .card-logo {
      height: 70px;
      margin-bottom: 1.25rem;
    }
  }
}

.card--aspect-09 {
  min-height: 380px;

  @media (min-width: 992px) {
    min-height: 450px;
  }
}

.card--border {
  --card-border-width: 1px;
  --card-border-color: var(--c-grey-light);
}

.card--box-shadow {
  --card-hover-box-shadow: var(--box-shadow);;
  transition: box-shadow .5s var(--transition-timing-function);
}

.card--news {
  --card-spacer-y: .75rem;
  --card-spacer-x: 0;
  --card-title-mt: .75rem;

  @media (min-width: 768px) {
    --card-spacer-y: 1.25rem;
    --card-spacer-x: 1.25rem;
    --card-title-mt: 1.25rem;
  }
}

.card--news-big {
  --card-spacer-y: 1rem;
  --card-spacer-x: 1rem;

  .card-img__wrapper {
    height: 100%;
    max-width: 410px;
  }

  @media (min-width: 992px) {
    --card-spacer-y: 2rem;
    --card-spacer-x: 2.5rem;
    --card-title-font-size: 22px;
  }
}

.card--news-small {
  --card-spacer-x: 0;

  .card-img__wrapper {
    max-height: 225px;
  }
}

.card--news-vertical {
  --card-spacer-x: 0;

  .card-img__wrapper {
    min-height: 400px;
  }
}


.card-date__wrapper {
  column-gap: 10px;

  @media (min-width: 992px) {
    column-gap: 14px;
  }
}

.card-img__wrapper {
  position: relative;
  width: 100%;
  height: 180px;
  border-radius: 2px;
  overflow: hidden;

  .card-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media (min-width: 768px) {
    height: 310px;
  }
}

.card-img--hover-scale {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform .8s var(--transition-timing-function);
}

.card:hover {
  .card-img--hover-scale {
    transform: scale3d(1.03, 1.03, 1);
  }
}

.card-news__tags {
  position: absolute;
  left: 0;
  bottom: .75rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: .25rem;

  @media (min-width: 992px) {
    bottom: 1.25rem;
  }
}

.card-date {
  display: flex;
  align-items: center;
  column-gap: 5px;
  color: var(--c-grey);
  font-size: 11px;
  line-height: 1.25;

  .icon {
    width: 12px;
    height: 12px;
  }

  @media (min-width: 992px) {
    font-size: 13px;

    .icon {
      width: 16px;
      height: 16px;
    }
  }
}

.card--usage {
  --card-title-font-size: 18px;
  width: 100%;
  aspect-ratio: 0.9;
  color: var(--c-white) !important;

  .card-img {
    position: relative;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      position: absolute;
      content: '';
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #090E14;
      opacity: 0.5;
      transition: opacity .5s;
      //z-index: 1;
    }
  }

  &:hover {
    .card-img:before {
      opacity: .3;
    }
  }

  @media (min-width: 992px) {
    --card-title-font-size: 28px;
  }
}

.card--video {
  --card-spacer-y: 1rem;
  --card-spacer-x: 0;
  --card-title-font-size: 13px;
  --card-bg: transparent;

  @media (min-width: 992px) {
    --card-spacer-y: 1.25rem;
    --card-title-font-size: 1rem;
  }
}

.card-video-btn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, .3);
}

.card--document {
  --card-spacer-y: 0;
  --card-spacer-x: 0;

  .card-img {
    height: 100%;
    max-height: 300px;
    border: 7px solid #FFFFFF;
    filter: drop-shadow(0px 4px 15px rgba(0, 26, 45, 0.03)) drop-shadow(0px 15px 40px rgba(0, 26, 45, 0.06));
  }

  @media (min-width: 992px) {
    --card-spacer-x: 1rem;
  }
}

.card--document-big {
  --card-title-font-size: 18px;

  @media (min-width: 992px) {
    --card-title-font-size: 22px;
  }
}

.card--article {
  --card-font-size: 12px;

  @media (min-width: 992px) {
    --card-font-size: 14px;
  }
}

.card--support {
  --card-line-height: 1.5;

  @media (min-width: 992px) {
    --card-spacer-y: 3rem;
    --card-spacer-x: 2.5rem;
    --card-title-font-size: 1.5rem;
  }
}
