// TODO
/**
Text colors
 */
.text-primary {
  --text-opacity: 1;
  //color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
  color: var(--c-primary) !important;
}

.text-danger {
  color: #DB4B54;
}

.text-purple {
  color: #800080;
}

.text-black {
  color: var(--c-black) !important;
}

.text-grey {
  color: var(--c-grey);
}

.text-grey-light {
  color: var(--c-grey-light);
}

.text-white {
  --text-opacity: 1;
  color: rgba(255, 255, 255, var(--text-opacity)) !important;
}

/**
Background colors
 */
.bg-primary {
  background-color: var(--c-primary) !important;
}

.bg-purple {
  background-color: #800080 !important;
}

.bg-dark {
  background-color: #293A48; // TODO color var
}

.bg-black-25 {
  background-color: #252525;
}

.bg-black-30 {
  background-color: #303030;
}

.bg-grey-94 {
  background-color: var(--c-grey-94);
}

.bg-grey-95 {
  // #f4f4f4
  background-color: var(--c-grey-95);
}

.bg-grey-f5 {
  // #f5f5f5
  background-color: var(--c-grey-f5);
}

/**
TODO
 */

//.text-secondary {
//  --bs-text-opacity: 1;
//  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
//}
//
//.text-success {
//  --bs-text-opacity: 1;
//  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
//}
//
//.text-info {
//  --bs-text-opacity: 1;
//  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
//}
//
//.text-warning {
//  --bs-text-opacity: 1;
//  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
//}
//
//.text-danger {
//  --bs-text-opacity: 1;
//  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
//}
//
//.text-light {
//  --bs-text-opacity: 1;
//  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
//}
//
//.text-dark {
//  --bs-text-opacity: 1;
//  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
//}


//

//
//.text-body {
//  --bs-text-opacity: 1;
//  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
//}
//
//.text-muted {
//  --bs-text-opacity: 1;
//  color: var(--bs-secondary-color) !important;
//}
//
//.text-black-50 {
//  --bs-text-opacity: 1;
//  color: rgba(0, 0, 0, 0.5) !important;
//}
//
//.text-white-50 {
//  --bs-text-opacity: 1;
//  color: rgba(255, 255, 255, 0.5) !important;
//}
