// https://github.com/twbs/bootstrap/blob/v5.2.3/site/content/docs/5.2/components/offcanvas.md
:root {
  --offcanvas-backdrop-bg: rgba(18, 18, 33, 0.6);
  --offcanvas-backdrop-opacity: 1;
}

.offcanvas {
  // scss-docs-start offcanvas-css-vars
  --offcanvas-zindex: var(--zi-offcanvas, 1045);
  --offcanvas-width: 400px;
  --offcanvas-height: 30vh;
  --offcanvas-padding-y: 1rem;
  --offcanvas-padding-x: 1rem;
  --offcanvas-color: var(--body-color);
  --offcanvas-bg: #fff;
  --offcanvas-border-width: 0;
  --offcanvas-border-color: transparent;
  --offcanvas-box-shadow: 0 0.125rem 0.25rem rgba(var(--bs-body-color-rgb), 0.075);;
  --offcanvas-transition: transform 0.3s ease-in-out;;
  --offcanvas-title-line-height: 1.5;
  // scss-docs-end offcanvas-css-vars

  position: fixed;
  bottom: 0;
  z-index: var(--offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--offcanvas-color);
  visibility: hidden;
  background-color: var(--offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--offcanvas-box-shadow);
  transition: var(--offcanvas-transition);

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &.showing,
  &.show:not(.hiding) {
    transform: none;
  }

  &.showing,
  &.hiding,
  &.show {
    visibility: visible;
  }

  // Placement
  &.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--offcanvas-width);
    border-right: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(-100%);
  }

  &.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--offcanvas-width);
    border-left: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateX(100%);
  }

  &.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-bottom: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(-100%);
  }

  &.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--offcanvas-height);
    max-height: 100%;
    border-top: var(--offcanvas-border-width) solid var(--offcanvas-border-color);
    transform: translateY(100%);
  }
}

.offcanvas-backdrop {
  @include overlay-backdrop(var(--zi-offcanvas-backdrop), var(--offcanvas-backdrop-bg), var(--offcanvas-backdrop-opacity));
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x);

  .btn-close {
    padding: calc(var(--offcanvas-padding-y) * .5) calc(var(--offcanvas-padding-x) * .5);
    margin-top: calc(-.5 * var(--offcanvas-padding-y));
    margin-right: calc(-.5 * var(--offcanvas-padding-x));
    margin-bottom: calc(-.5 * var(--offcanvas-padding-y));
  }
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--offcanvas-padding-y) var(--offcanvas-padding-x);
  overflow-y: auto;
}


/**
Theme styles
 */
.offcanvas--default {
  --offcanvas-width: 100%;
  --offcanvas-padding-y: 10px;

  .offcanvas-body {
    margin-top: 50px;
  }

  @media (min-width: 992px) {
    .offcanvas-body {
      margin-top: 80px;
    }
  }
}

#offcanvasSearch {
  --offcanvas-padding-x: 0;
  --offcanvas-height: auto;
  bottom: auto;

  @media (min-width: 992px) {
    --offcanvas-padding-y: 4rem;
  }

  @media (min-width: 1400px) {
    --offcanvas-padding-y: 7.5rem;
  }
}
