// TODO
// TODO Bootstrap
.nav {
  // scss-docs-start nav-css-vars
  --nav-link-padding-x: 1rem;
  --nav-link-padding-y: 0.5rem;
  --nav-link-bg-color: #fff;
  --nav-link-color: var(--c-text);
  --nav-link-active-color: var(--c-primary);
  --nav-link-hover-color: var(--c-primary);
  --nav-link-hover-bg-color: #fff;
  --nav-link-font-weight: normal;
  //--nav-link-disabled-color: var(--bs-secondary-color);
  --nav-link-font-size: 1rem;
  --nav-link-line-height: 1.2;
  --nav-link-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--nav-link-padding-y) var(--nav-link-padding-x);
  background-color: var(--nav-link-bg-color);
  color: var(--nav-link-color);
  font-size: var(--nav-link-font-size);
  font-weight: var(--nav-link-font-weight);
  line-height: var(--nav-link-line-height);
  text-decoration: none;
  transition: var(--nav-link-transition);
  cursor: pointer;

  &:hover,
  &:focus {
    background-color: var(--nav-link-hover-bg-color);
    color: var(--nav-link-hover-color);
    text-decoration: none;
  }

  &.active {
    color: var(--nav-link-active-color);
  }

  &.disabled {
    color: var(--nav-link-disabled-color);
    pointer-events: none;
    cursor: default;
  }
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}

//
// Pills
//
.nav-pills {
  // scss-docs-start nav-pills-css-vars
  --nav-link-padding-x: 1rem;
  --nav-link-padding-y: 10px;
  --nav-pills-border-radius: 8px;
  --nav-link-bg-color: #F0F0F0;
  --nav-link-hover-color: var(--c-black);
  --nav-link-hover-bg-color: #E0E0E0; // todo color var
  --nav-pills-link-active-color: #fff;
  --nav-pills-link-active-bg: var(--c-primary);
  --nav-link-font-size: 13px;
  // scss-docs-end nav-pills-css-vars
  gap: 12px;

  .nav-link {
    border-radius: var(--nav-pills-border-radius);

    &:disabled {
      //color: var(--nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .show > .nav-link {
    color: var(--nav-pills-link-active-color);
    background-color: var(--nav-pills-link-active-bg);
  }

  @media (min-width: 1200px) {
    --nav-link-padding-x: 30px;
    --nav-link-padding-y: 12px;
    --nav-link-font-size: 1rem;
    gap: 24px;
  }
}

//
// Justified variants
//
.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

.nav-fill,
.nav-justified {
  .nav-item .nav-link {
    width: 100%; // Make sure button will grow
  }
}

/**
Theme styles
 */
//
// Underline
//
.nav-link--underline {
  // scss-docs-start nav-underline-css-vars
  //--nav-underline-gap: #{$nav-underline-gap};
  --nav-underline-border-width: 3px;
  --nav-underline-link-active-color: var(--c-primary);
  // scss-docs-end nav-underline-css-vars
  position: relative;


  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    width: 0;
    height: var(--nav-underline-border-width);
    background-color: var(--nav-underline-link-active-color);
    transition: width .3s;
  }

  &.active,
  &:hover {
    //border-bottom-color: currentcolor;
    &:before {
      width: 100%;
    }
  }
}

.nav--main {
  --nav-link-font-weight: 500;

  @media (min-width: 1400px) {
    --nav-link-padding-x: 0;
    --nav-link-padding-y: 2rem;

    column-gap: 40px;
  }
}

.nav--tools {
  --nav-link-padding-x: 0;
  --nav-link-padding-y: 10px;
  column-gap: 22px;

  .icon {
    width: 20px;
    height: 20px;
  }

  @media (min-width: 1200px) {
    .icon {
      width: 24px;
      height: 24px;
    }
  }
}

.mobile-nav {
  --nav-link-padding-x: 0;
  --nav-link-padding-y: 13px;
  --nav-link-font-size: 14px;
  --nav-link-font-weight: 500;

  .nav-item {
    border-bottom: 1px solid var(--c-grey-light);
  }
}


.mobile-menu__back-link {
  display: block;
  padding: 13px 0;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  .icon-text {
    position: relative;
    justify-content: center;
  }

  .icon {
    position: absolute;
    left: 0;
  }
}

.nav--side-menu {
  --nav-link-padding-x: 1rem;
  --nav-link-padding-y: 1rem;
  background-color: #fff;
  padding: 1rem 0;

  @media (min-width: 992px) {
    --nav-link-padding-x: 2rem;
    padding: 2rem 0;
    max-width: 250px;
    margin-left: auto;
  }

  .nav-link {
    position: relative;
  }

  .nav-link:before {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    bottom: 100%;
    left: 0;
    width: 3px;
    background-color: var(--c-red-secondary);
    transition: bottom .3s;
  }

  .nav-link:hover,
  .nav-link.active {
    &:before {
      bottom: 0;
    }
  }
}
