.breadcrumb {
  // scss-docs-start breadcrumb-css-vars
  --breadcrumb-padding-y: 1rem;
  --breadcrumb-padding-x: 0;
  --breadcrumb-margin-bottom: 1rem;
  --breadcrumb-font-size: 1rem;
  --breadcrumb-bg: ;
  --breadcrumb-border-radius: ;
  --breadcrumb-divider: '/';
  --breadcrumb-divider-color: ;
  --breadcrumb-item-padding-x: .25rem;
  --breadcrumb-item-color: ;
  --breadcrumb-item-active-color: ;
  // scss-docs-end breadcrumb-css-vars

  display: flex;
  flex-wrap: wrap;
  padding: var(--breadcrumb-padding-y) var(--breadcrumb-padding-x);
  margin-bottom: var(--breadcrumb-margin-bottom);
  font-size: var(--breadcrumb-font-size);
  list-style: none;
  background-color: var(--breadcrumb-bg);
  border-radius: var(--breadcrumb-border-radius);
}

.breadcrumb-item {
  // The separator between breadcrumbs (by default, a forward-slash: "/")
  + .breadcrumb-item {
    padding-left: var(--breadcrumb-item-padding-x);

    &::before {
      float: left; // Suppress inline spacings and underlining of the separator
      padding-right: var(--breadcrumb-item-padding-x);
      color: var(--breadcrumb-divider-color);
      content: var(--breadcrumb-divider);
    }
  }

  &.active {
    color: var(--breadcrumb-item-active-color);
  }
}

/**
Theme styles
 */
.breadcrumb--default {
  --breadcrumb-padding-y: 5px;
  --breadcrumb-font-size: 11px;
  flex-wrap: nowrap;

  .breadcrumb-item {
    display: flex;
    align-items: center;

    span {
      white-space: nowrap;
    }
  }

  .breadcrumb-item:last-of-type {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media (min-width: 768px) {
    --breadcrumb-padding-y: 1rem;
    --breadcrumb-font-size: 14px;
  }
}
