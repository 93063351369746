// TODO
//Sizing
//Dark dropdowns
//Directions
//Centered
//Dropup
//Dropup centered
//Dropend
//Dropstart
//Menu items
//Active
//Disabled
// + Menu alignment
// + Responsive alignment
//Alignment options
//Menu content
//Headers
// + Dividers
//Text
//Forms
//Dropdown options
//Auto close behavior
//CSS
//Variables
//Sass variables
//Mixins
//Usage
//Via data attributes
//Via JavaScript
//Options
//Using function with popperConfig
//Methods
//Events

// TODO add dropdown-backdrop class

// The dropdown wrapper (`<div>`)
.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;

  // Generate the caret automatically
  //@include caret();
}

.dropdown-menu {
  // scss-docs-start dropdown-css-vars
  --dropdown-zindex: var(--zi-dropdown, 1000);
  --dropdown-min-width: 10rem;
  --dropdown-padding-x: 0;
  --dropdown-padding-y: 1rem;
  //--dropdown-spacer: 0.125rem;
  --dropdown-font-size: 14px;
  --dropdown-bg: #fff;
  --dropdown-border-color: #F1F1F1;
  --dropdown-border-radius: 10px;
  --dropdown-border-width: 1px;
  //--dropdown-inner-border-radius: calc(0.375rem - var(--border-width));
  --dropdown-box-shadow: 0 10px 60px 0 rgba(0, 0, 0, 0.08);
  --dropdown-divider-bg: ;
  --dropdown-divider-margin-y: 0.5rem;
  //--dropdown-box-shadow: 0 0.5rem 1rem rgba(var(--body-color-rgb), 0.15);
  --dropdown-color: var(--body-color);
  //--dropdown-link-hover-color: var(--body-color);
  //--dropdown-link-hover-bg: var(--tertiary-bg);
  //--dropdown-link-active-color: #fff;
  //--dropdown-link-active-bg: #0d6efd;
  //--dropdown-link-disabled-color: #adb5bd;
  --dropdown-item-padding-x: 1rem;
  --dropdown-item-padding-y: 0.25rem;
  //--dropdown-header-color: #6c757d;
  --dropdown-header-padding-x: 1rem;
  --dropdown-header-padding-y: 1rem;

  position: absolute;
  z-index: var(--dropdown-zindex);
  display: none;
  min-width: var(--dropdown-min-width);
  margin: 0;
  padding: var(--dropdown-padding-y) var(--dropdown-padding-x);
  background-color: var(--dropdown-bg);
  background-clip: padding-box;
  color: var(--dropdown-color);
  font-size: var(--dropdown-font-size);
  text-align: left;
  list-style: none;
  border: var(--dropdown-border-width) solid var(--dropdown-border-color);
  border-radius: var(--dropdown-border-radius);
  box-shadow: var(--dropdown-box-shadow);

  &[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: var(--dropdown-spacer);
  }
}

/**
Menu alignment
*/
// scss-docs-start responsive-breakpoints
// We deliberately hardcode the `bs-` prefix because we check
// this custom property in JS to determine Popper's positioning
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .dropdown-menu#{$infix}-start {
      --position: start;

      &[data-bs-popper] {
        right: auto;
        left: 0;
      }
    }

    .dropdown-menu#{$infix}-end {
      --position: end;

      &[data-bs-popper] {
        right: 0;
        left: auto;
      }
    }
  }
}
/**
END Menu alignment
*/

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
  .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: var(--dropdown-spacer);
  }

  .dropdown-toggle {
    //@include caret(up);
  }
}

.dropend {
  .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: var(--dropdown-spacer);
  }

  .dropdown-toggle {
    //@include caret(end);
    &::after {
      vertical-align: 0;
    }
  }
}

.dropstart {
  .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: var(--dropdown-spacer);
  }

  .dropdown-toggle {
    //@include caret(start);
    &::before {
      vertical-align: 0;
    }
  }
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
  height: 0;
  margin: var(--dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--dropdown-divider-bg);
  opacity: 1;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
  clear: both;
  color: var(--dropdown-color);
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--dropdown-item-border-radius, 0);

  &:hover,
  &:focus {
    color: var(--dropdown-link-hover-color);
    background-color: var(--dropdown-link-hover-bg);
  }

  &.active,
  &:active {
    color: var(--dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--dropdown-link-active-bg);
  }

  &.disabled,
  &:disabled {
    color: var(--dropdown-link-disabled-color);
    pointer-events: none;
    background-color: transparent;
  }
}

// Dropdown section headers
.dropdown-header {
  display: block;
  padding: var(--dropdown-header-padding-y) var(--dropdown-header-padding-x);
  margin-bottom: 0;
  //font-size: 0.875rem;
  color: var(--dropdown-header-color);
  white-space: nowrap;
}

// Dropdown text
.dropdown-item-text {
  display: block;
  padding: var(--dropdown-item-padding-y) var(--dropdown-item-padding-x);
  color: var(--dropdown-color);
}

// todo make universal styles
.dropdown-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, .3);
  opacity: 0;
  z-index: -1;
}

.is-dropdown-active .dropdown-backdrop {
  z-index: var(--zi-dropdown-backdrop);
  opacity: 1;
  transition: opacity .3s ease-in-out;
}

.dropdown-menu.show {
  display: block;
}

// Hoverable Dropdown
.dropdown-caret {
  transition: transform .2s;
}

.dropdown-hoverable:hover {
  .dropdown-menu {
    display: block;
    //margin-top: 2px;
  }

  .dropdown-caret {
    transform: rotate(180deg) translateY(-2px);
  }
}

/**
Theme
 */
.dropdown-menu--default {
  --dropdown-min-width: 15rem;
  --dropdown-border-color: var(--c-grey-light);
  --dropdown-border-radius: 0 0 .5rem .5rem;
  //--dropdown-box-shadow: none;
  --dropdown-padding-x: 0;
  --dropdown-padding-y: 6px;
  --dropdown-item-padding-x: 0;
  --dropdown-item-padding-y: 0;
  --nav-link-padding-x: 1.5rem;
  --nav-link-padding-y: 1rem;

  .dropdown-item:not(:last-of-type) {
    border-bottom: 1px solid var(--c-grey-light);
  }
}

.dropdown-menu--lang {
  --dropdown-min-width: 5rem;
  --nav-link-padding-x: 20px;
  --nav-link-padding-y: 15px;
  --nav-link-active-color: var(--c-red-secondary);
}

