.contacts-item {
  position: relative;
  padding-left: 2rem;
  margin-bottom: 1.25rem;
}

.contacts-item__icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  color: var(--c-red-secondary);
}


.contacts-item__title {
  margin: 3px 0;
  color: var(--c-grey);
  font-size: 14px;
  line-height: 1.3;
}
