/*MENU-TOGGLE*/
.burger-btn {
  position: relative;
  display: block;
  width: 25px;
  height: 40px;
  //padding: 9px;
  //border-radius: 50%;
  background-color: transparent; // todo color var
  color: var(--c-text); // todo color var
}

.burger-btn__line {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  //margin-top: 8px;
  //margin-bottom: 8px;
  user-select: none;

  &,
  &::before,
  &::after {
    display: block;
    //width: 22px;
    //height: 2px;
    background-color: currentColor;
    outline: 1px solid transparent;
    transition-property: background-color, transform;
    transition-duration: .2s;

    //theme
    width: 18px;
    height: 2px;
    border-radius: 0;
  }

  &::before,
  &::after {
    position: absolute;
    content: "";
  }

  &::before {
    // theme
    top: -6px
  }

  &::after {
    //theme
    top: 6px
  }
}

.is-active .burger-btn__line {
  background-color: transparent
}

//TODO postition animation
.is-active .burger-btn__line::before {
  transform: translateY(8px) rotate(45deg);
  top: -10px;
}

.is-active .burger-btn__line::after {
  transform: translateY(-8px) rotate(-45deg);
  bottom: 8px;
}
