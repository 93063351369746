// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
  // scss-docs-start badge-css-vars
  --badge-padding-y: 0.35rem;
  --badge-padding-x: 0.65rem;
  --badge-font-size: 0.75rem;
  --badge-font-weight: 700;
  --badge-bgc: ;
  --badge-color: #fff;
  --badge-border-radius: 0.375rem;
  // scss-docs-end badge-css-vars

  display: inline-block;
  padding: var(--badge-padding-y) var(--badge-padding-x);
  font-size: var(--badge-font-size);
  font-weight: var(--badge-font-weight);
  line-height: 1;
  background-color: var(--badge-bgc);
  color: var(--badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--badge-border-radius);
  position: relative;

  // Empty badges collapse automatically
  &:empty {
    display: none;
  }
}

// Quick fix for badges in buttons
.btn .badge {
  position: relative;
  top: -1px;
}

/**
Theme styles
 */
.badge--default {
  --badge-padding-y: 0.25rem;
  --badge-padding-x: 1rem;
  --badge-font-size: 11px;
  --badge-font-weight: 700;
  --badge-color: #fff;
  --badge-border-radius: 2px;
  line-height: 1.25;

  @media (min-width: 992px) {
    --badge-padding-y: 0.375rem;
    --badge-padding-x: 1.25rem;
    --badge-font-size: 12px;
  }
}

.badge--news {
  --badge-bgc: #5085ED;
}

.badge--event {
  --badge-bgc: #7B61FF;
}

.badge--report {
  --badge-bgc: #4BB48E;
}

.badge--special {
  --badge-bgc: #EA393D;

  &:after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    bottom: 0;
    right: -15px;
    border-width: 10px;
    border-style: solid;
    border-color: #EA393D;
    border-right-color: transparent;
    border-radius: 2px;
  }

  @media (min-width: 992px) {
    &:after {
      border-width: 12px;
    }
  }
}
