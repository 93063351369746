.icon-text {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
}

.icon--full-size {
  display: flex;
  width: 100%;
  height: 100%;
}

svg.icon {
  fill: currentColor;
}
