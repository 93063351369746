.page-header--breadcrumb {
  margin-bottom: 2rem;

  @media (min-width: 992px) {
    margin-bottom: 3.75rem;
  }
}

.page-header--banner {
  position: relative;

  .section__bg {
    height: 81%;
  }

  @media (min-width: 992px) {
    .section__bg {
      height: 585px;
    }
  }
}

.page-banner {
  position: relative;
  max-width: 1440px;
  height: 180px;
  margin: 0 auto 2rem;
  color: var(--c-white);
  z-index: 1;

  &:after {
    // overlay bg
    position: absolute;
    content: '';
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    opacity: 0.4;
    z-index: -1;
  }

  @media (min-width: 768px) {
    height: 20rem;
  }

  @media (min-width: 1200px) {
    height: 31.25rem;
    margin-bottom: 5rem;
  }
}

.page-banner__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -2;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-banner__content {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  padding: 1.25rem 0;
  color: var(--c-white);

  @media (min-width: 992px) {
    padding: 3.75rem;
  }
}

.page-banner__title {
  //max-width: 865px;
  margin: 0;
  color: var(--c-white);
  font-size: 1rem;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1200px) {
    font-size: 2.25rem;
  }
}

.page-content {
  padding-bottom: 4rem;
}

.page-content__annotation {
  font-size: 14px;

  @media (min-width: 992px) {
    font-size: 20px;
  }
}

.content {
  // todo
  margin-bottom: 3rem;

  a:not([class]) {
    color: var(--c-red-secondary);
  }

  hr {
    border-bottom: 1px solid #DFDFDF;
  }

  p {
    line-height: 1.5;
    margin-top: 0;
    margin-bottom: 1.375rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ul, ol {
    padding-left: 0;
    margin-bottom: 3rem;
    list-style: none;
  }

  ul li,
  ol li {
    padding-left: 1rem;
    margin-bottom: 1.125rem;
    position: relative;

    @media (min-width: 992px) {
      padding-left: 1.75rem;
      margin-bottom: 1.375rem;
    }
  }

  ul {
    li {
      &:before {
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        background-color: #EA393D;
        border-radius: 50%;
        position: absolute;
        top: .5em;
        left: 0;
      }
    }
  }

  ol {
    counter-reset: li;

    li {
      &:before {
        content: counter(li) ".";
        counter-increment: li;
        font-weight: 600;
        color: #EA393D;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.content-block {
  margin-bottom: 3rem;

  h4 {
    position: relative;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: 40px;
      height: 3px;
      margin-right: 30px;
      background-color: var(--c-red);
    }
  }

  figure {
    margin: 1.5rem 0;

    img {
      width: 100%;
    }
  }

  figcaption {
    position: relative;
    padding: 14px 2rem;
    margin-top: 10px;
    background-color: #F6F6F6;
    font-size: 14px;

    &:after {
      /*TODO shape universal styles*/
      position: absolute;
      content: '';
      display: block;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      background-color: var(--c-grey-95);
      border-bottom: 14px solid var(--c-primary);
      border-left: 22px solid #fff;
    }
  }

  @media (min-width: 992px) {
    margin-bottom: 6rem;

    figure {
      margin: 2.5rem 0;
    }
  }
}

.achievement-item {
  margin-bottom: 1.5rem;

  @media (min-width: 992px) {
    margin-bottom: 2.5rem;
  }
}

.achievement-item__value {
  color: var(--c-red-secondary);
  font-size: 2rem;
  white-space: nowrap;

  @media (min-width: 992px) {
    font-size: 60px;
  }
}

//Event Page
.event-banner {
  padding: 20px 0px;
  background-color: #303030;
  border-radius: 10px;
  color: #fff;

  @media (min-width: 992px) {
    padding: 40px 50px;
  }
}
.event-banner--form {
  padding: 20px 20px;

  @media (min-width: 992px) {
    padding: 50px 120px 60px 50px;
  }
}

.event-banner__title {
  font-size: 1.5rem;
  font-weight: 700;

  @media (min-width: 992px) {
    font-size: 2rem;
  }
}

.event-banner__date-day {
  font-size: 30px;
  font-weight: 700;

  @media (min-width: 992px) {
    font-size: 60px;
  }
}

.content-block--event {

  @media (min-width: 992px) {
    img {
      max-width: 690px;
      margin: auto;
    }
  }
}
//END Event Page

