.section {
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (min-width: 1200px) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

.section--grey {
  background-color: var(--c-grey-95);
}

.section--black {
  --section-color: #fff;
  background-color: #252525;
  color: #fff;
}

.section__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
}

.section-title {
  margin-bottom: 1.5rem;
  color: var(--section-color, var(--c-black));

  @media (min-width: 992px) {
    margin-bottom: 2.5rem;
  }
}

.section-label {
  color: var(--c-red-secondary);
  font-size: 13px;
  font-weight: 500;
  line-height: 1.25;
  margin-bottom: 1rem;

  @media (min-width: 1200px) {
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 10px;
    transform: rotate(-90deg) translateX(-100%);
    transform-origin: top left;
    font-size: 1rem;

    & + .h2 {
      margin-left: 5rem;
    }
  }
}
