.search-results {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}


.search-results__count {
  margin: 0;
  color: var(--c-grey);
}

.search-results__item {
  padding: 1rem 0;
  color: var(--c-grey);
  font-size: 1rem;
  line-height: 1.25;
  border-bottom: 1px solid var(--c-grey-light);

  strong {
    color: var(--c-black);
  }

  @media (min-width: 992px) {
    padding: 1.5rem 0;
    font-size: 22px;
  }

  @media (min-width: 1200px) {
    padding-right: 20%;
  }
}
