.pagination {
  // scss-docs-start pagination-css-vars
  --pagination-spacing-x: 4px;
  --pagination-padding-x: 13px;
  --pagination-padding-y: 10px;
  --pagination-font-size: .875rem;
  --pagination-line-height: 1;
  --pagination-font-weight: 600;
  --pagination-color: var(--c-grey);
  --pagination-bg: #fff;
  --pagination-border-width: 0;
  --pagination-border-color: ;
  --pagination-border-radius: 2px;
  --pagination-hover-color: var(--c-red-secondary);
  --pagination-hover-bg: var(--pagination-bg);
  --pagination-hover-border-color: var(--pagination-border-color);
  --pagination-focus-color: var(--pagination-hover-color);
  --pagination-focus-bg: var(--pagination-hover-bg);
  --pagination-focus-box-shadow: ;
  --pagination-active-color: #F4F7FB;
  --pagination-active-bg: var(--c-red-secondary);
  --pagination-active-border-color: ;
  --pagination-disabled-color: var(--pagination-color);
  --pagination-disabled-bg: var(--c-grey-light);
  --pagination-disabled-border-color: var(--pagination-border-color);
  --pagination-disbled-opacity: .85;
  --pagination-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;;
  // scss-docs-end pagination-css-vars

  @media (min-width: 992px) {
    --pagination-padding-x: 12px;
    --pagination-padding-y: 13px;
    --pagination-font-size: 1rem;
  }
}

.pagination-list {
  display: flex;
  padding-left: 0;
  list-style: none;
  column-gap: var(--pagination-spacing-x);
}

.pagination-link {
  position: relative;
  display: block;
  padding: var(--pagination-padding-y) var(--pagination-padding-x);
  color: var(--pagination-color);
  font-size: var(--pagination-font-size);
  line-height: var(--pagination-line-height);
  font-weight: var(--pagination-font-weight);
  text-decoration: none;
  background-color: var(--pagination-bg);
  border: var(--pagination-border-width) solid var(--pagination-border-color);
  border-radius: var(--pagination-border-radius);
  transition: var(--pagination-transition);
  cursor: pointer;

  .icon {
    width: .8em;
    height: .8em;
  }

  &:hover {
    z-index: 2;
    color: var(--pagination-hover-color);
    //text-decoration: none;
    background-color: var(--pagination-hover-bg);
    border-color: var(--pagination-hover-border-color);
  }

  &:focus {
    z-index: 3;
    color: var(--pagination-focus-color);
    background-color: var(--pagination-focus-bg);
    outline: var(--pagination-focus-outline);
    box-shadow: var(--pagination-focus-box-shadow);
  }

  &.active {
    z-index: 3;
    color: var(--pagination-active-color);
    background-color: var(--pagination-active-bg);
    border-color: var(--pagination-active-border-color);
  }

  &.disabled {
    color: var(--pagination-disabled-color);
    pointer-events: none;
    background-color: var(--pagination-disabled-bg);
    border-color: var(--pagination-disabled-border-color);
    opacity: var(--pagination-disbled-opacity);
  }
}

.pagination-item {
  &:not(:first-child) .page-link {
    //margin-left: $pagination-margin-start;
  }
}


//
// Sizing
//
.pagination-lg {
  //@include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $pagination-border-radius-lg);
}

.pagination-sm {
  //@include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $pagination-border-radius-sm);
}
