// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header {
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: var(--c-white);
  border-bottom: 1px solid var(--c-grey-light);
  z-index: var(--zi-header);

  @media (min-width: 992px) {
    //padding-top: 22px;
    //padding-bottom: 22px;
  }

  @media (min-width: 1200px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.header__logo {
  max-width: 210px;

  @media (min-width: 1400px) {
    max-width: 240px;
    margin-right: 40px;
  }
}
