@charset 'UTF-8';
// TODO verify folder's order

/**
0. Vendors
 */
@import 'vendor/normalize';

/**
1. Abstracts
 */
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

/**
2. Base stuff
 */
@import 'base/base';
@import 'base/grid';
@import 'base/fonts';
@import 'base/helpers';
@import 'base/typography';


// 5. Components
@import 'components/accordion';
//@import 'components/alert';
@import 'components/badge';
@import 'components/breadcrumb';
@import 'components/button';
@import 'components/burger';
@import 'components/card';
@import 'components/collapse';
@import 'components/contacts-item';
@import 'components/dropdown';
@import 'components/form';
@import 'components/icon';
@import 'components/link';
@import 'components/modal';
@import 'components/nav';
@import 'components/nav-tabs';
@import 'components/offcanvas';
@import 'components/pagination';
@import 'components/search';
@import 'components/slider';

// Utilities
@import 'utilities/animate/index';
@import 'utilities/colors';
@import 'utilities/display';
@import 'utilities/flex';
@import 'utilities/overflow';
@import 'utilities/position';
@import 'utilities/sizing';
@import 'utilities/spacing';
@import 'utilities/text';

// 4. Layout-related sections
@import 'layout/header';
@import 'layout/footer';
@import 'layout/section';

// 6. Page-specific styles
@import 'pages/page';
@import 'pages/404';
@import 'pages/home';
@import 'pages/search';
@import 'pages/company';

// 7. Themes
@import 'themes/default';
