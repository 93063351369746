.section--portfolio {

  .section__bg {
    height: 455px;

    @media (min-width: 1200px) {
      height: 430px;
    }
  }
}

.portfolio-slider {
  .card {
    /*todo height 100% doesn't work*/
    //min-height: 380px;
    height: 380px;
  }

  @media (min-width: 992px) {
    .card {
      /*todo height 100% doesn't work*/
      //min-height: 450px;
      height: 450px;
    }
  }

  /*@media (min-width: 1200px) {
    .slider__wide-wrapper {
      width: 70vw;
      overflow: hidden;
      margin: -40px;
      padding: 40px;
    }
  }*/
}
