:root {
  --animate-duration: 1s;
  --animate-delay: 0;
  --animate-repeat: 1; // 1 / infinite
}

.animate__animated {
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-delay: var(--animate-delay);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-iteration-count: var(--animate-repeat);
  animation-timing-function: ease;
}

.animate-wrapper {
  overflow: hidden;
}

// AOS
[data-aos] {
  visibility: hidden;
}

[data-aos].animate__animated {
  visibility: visible;
}
