@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(var(--animate-offset, -100%), 0, 0);
    transform: translate3d(var(--animate-offset, -100%), 0, 0)
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(var(--animate-offset, -100%), 0, 0);
    transform: translate3d(var(--animate-offset, -100%), 0, 0)
  }
  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

.animate__fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft
}
