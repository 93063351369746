@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $key, $size in $spacers {
        .#{$abbrev}#{$infix}-#{$key} {
          #{$prop}: #{$size} !important;
        }

        .#{$abbrev}t#{$infix}-#{$key},
        .#{$abbrev}y#{$infix}-#{$key} {
          #{$prop}-top: #{$size} !important;
        }

        .#{$abbrev}r#{$infix}-#{$key},
        .#{$abbrev}x#{$infix}-#{$key} {
          #{$prop}-right: #{$size} !important;
        }

        .#{$abbrev}b#{$infix}-#{$key},
        .#{$abbrev}y#{$infix}-#{$key} {
          #{$prop}-bottom: #{$size} !important;
        }

        .#{$abbrev}l#{$infix}-#{$key},
        .#{$abbrev}x#{$infix}-#{$key} {
          #{$prop}-left: #{$size} !important;
        }
      }
    }

    .m#{$infix}-a {
      margin: auto !important;
    }

    .mt#{$infix}-a,
    .my#{$infix}-a {
      margin-top: auto !important;
    }

    .mr#{$infix}-a,
    .mx#{$infix}-a {
      margin-right: auto !important;
    }

    .mb#{$infix}-a,
    .my#{$infix}-a {
      margin-bottom: auto !important;
    }

    .ml#{$infix}-a,
    .mx#{$infix}-a {
      margin-left: auto !important;
    }
  }
}
