/*Home page - Main slider*/
.main-slider {
  position: relative;
  // todo temp
  //background-color: var(--c-grey-light);

  .slider-pagination {
    display: flex;
    padding: 0;
    column-gap: 30px;
  }
}

.main-slider-content {
  position: relative;
  color: var(--c-black);
  font-size: 1rem;
  line-height: 1.25;
  z-index: 2;

  [data-color-type="dark"] {
    color: var(--c-white);
  }

  .swiper-slide {
    padding-top: 2rem;
    padding-bottom: 8rem;
  }

  .swiper-slide:not(.swiper-slide-active) {
    .animate__wrapper {
      opacity: 0;
    }
  }

  @media (min-width: 768px) {
    font-size: 1.5rem;

    .swiper-slide {
      padding-top: 12rem;
      padding-bottom: 2rem;
    }
  }

  @media (min-width: 1200px) {
    font-size: 2rem;

    .swiper-slide {
      padding-top: 24rem;
    }
  }
}

.main-slider.inverse {
  .slider-controls {
    --slider-control-color: var(--c-white);
  }
}

.slider-counter {
  display: flex;
  align-items: center;
  margin: 0 1rem;
}

.slider-counter__slider {
  height: 28px;
}

.slider-counter__separator {
  margin: 0 6px;
}

.slider-counter__value {
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  color: var(--slider-control-color);
}

.slider-pagination-bar {
  position: relative;
  flex: 1 0 auto;
  width: auto;
  height: 3px;
  background-color: var(--c-grey);
  cursor: pointer;
}

.slider-pagination-bar-active:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: calc(var(--progress) * 100%);
  background-color: var(--c-secondary);
  z-index: 1;
}

/*Home page - Main slider*/
.main-slider-images {
  position: absolute;
  top: 0;
  bottom: 0;
  //left: 0;
  //right: 0;
  width: 100%;
  z-index: -1;

  .swiper-wrapper {
    //transition-duration: .8s !important;
    transition-timing-function: ease-out;
  }

  .swiper-slide {
    overflow: hidden;
  }

  /*&:nth-of-type(2) {
    left: 25%;

    .swiper-wrapper {
      transition-delay: 75ms;
    }
  }

  &:nth-of-type(3) {
    left: 50%;

    .swiper-wrapper {
      transition-delay: 150ms;
    }
  }

  &:nth-of-type(4) {
    left: 75%;

    .swiper-wrapper {
      transition-delay: 225ms;
    }
  }*/
}

.animate__wrapper {
  overflow: hidden;
}
