//
// Tabs
//
.nav-tabs {
  // scss-docs-start nav-tabs-css-vars
  --nav-tabs-border-width: ;
  //--nav-tabs-border-color: #{$nav-tabs-border-color};
  //--nav-tabs-border-radius: #{$nav-tabs-border-radius};
  //--nav-tabs-link-hover-border-color: #{$nav-tabs-link-hover-border-color};
  --nav-tabs-link-active-color: ;
  --nav-tabs-link-active-bg: ;
  //--nav-tabs-link-active-border-color: #{$nav-tabs-link-active-border-color};
  // scss-docs-end nav-tabs-css-vars

  border-bottom: var(--nav-tabs-border-width) solid var(--nav-tabs-border-color);

  .nav-link {
    margin-bottom: calc(-1 * var(--nav-tabs-border-width)); // stylelint-disable-line function-disallowed-list
    border: var(--nav-tabs-border-width) solid transparent;
    //@include border-top-radius(var(--nav-tabs-border-radius));

    &:hover,
    &:focus {
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      isolation: isolate;
      border-color: var(--nav-tabs-link-hover-border-color);
    }

    &.disabled,
    &:disabled {
      color: var(--nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    color: var(--nav-tabs-link-active-color);
    background-color: var(--nav-tabs-link-active-bg);
    border-color: var(--nav-tabs-link-active-border-color);
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: calc(-1 * var(--nav-tabs-border-width)); // stylelint-disable-line function-disallowed-list
    // Remove the top rounded corners here since there is a hard edge above the menu
    //@include border-top-radius(0);
  }
}

// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`
.tab-content {
  > .tab-pane {
    display: none;
  }

  > .active {
    display: block;
  }
}

/**
Theme styles
 */
.nav-tabs--default {
  --nav-link-padding-y: 14px;
  --nav-link-padding-x: 0;
  --nav-link-bg-color: transparent;
  --nav-link-hover-color: var(--c-red-secondary);
  --nav-link-hover-bg-color: var(--nav-link-bg-color);
  --nav-link-font-weight: 700;
  --nav-link-font-size: 14px;
  --nav-tabs-link-active-color: var(--c-red-secondary);
  --nav-tabs-border-width: 0;
  column-gap: 2rem;

  @media (min-width: 992px) {
    --nav-link-font-size: 18px;
    column-gap: 3rem;
  }
}

.aside-tabs {
  --nav-link-padding-x: 0;
  --nav-link-padding-y: 1rem;
  --nav-link-bg-color: transparent;
  --nav-link-active-bg: transparent;
  --nav-link-hover-bg-color: transparent;
  --nav-tabs-border-width: 1;
  --nav-link-color: var(--c-grey);
  --nav-link-active-color: var(--c-black);
  --nav-link-hover-color: var(--c-black);
  --nav-link-font-size: 1rem;
  --nav-link-font-weight: 700;
  --nav-link-line-height: 1.22;
  --nav-link-transition: color var(--transition-duration) var(--transition-timing-function);

  .nav-item {
    border-bottom: 1px solid #DFDFDF;
  }

  .nav-link {
    width: 100%;
    text-align: left;
  }

  @media (min-width: 1200px) {
    --nav-link-padding-y: 22px;
    --nav-link-font-size: 18px;
  }
}

.aside-tabs__wrapper {
  background-color: var(--c-grey-95);
  padding: 30px 40px 40px 75px;
  transform: translateX(-75px);
}

.aside-tabs__item-marker {
  position: relative;
  flex-shrink: 0;
  width: 22px;
  height: 23px;
  color: var(--c-primary);
  margin-right: 1rem;
  overflow: hidden;
  transition: color var(--transition-duration) var(--transition-timing-function);

  &:after {
    position: absolute;
    content: '';
    display: block;
    left: 0;
    bottom: 5px;
    right: 0;
    width: 0;
    height: 0;
    background-color: var(--c-grey-95);
    border-bottom: 14px solid var(--c-primary);
    border-left: 22px solid transparent;
    opacity: 0;
    transform: translate(100%, 100%);
    transition-property: opacity, transform;
    transition-duration: var(--transition-duration);
    transition-timing-function: var(--transition-timing-function);
  }

  .active & {
    color: transparent;

    &:after {
      opacity: 1;
      transform: translate(0%, 0%);
    }
  }
}

.aside-tabs__content {
  @media (min-width: 1200px) {

    .tab-pane {
      position: absolute;
      opacity: 0;
      top: 0;
      display: block !important;

      .card-body {
        opacity: 0;
        transform: translateY(20px);
        transition-property: opacity, transform;
        transition-duration: .8s;
        transition-timing-function: var(--transition-timing-function);
      }

      &.active {
        position: relative;
        opacity: 1;

        .card-body {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    }
  }
}

