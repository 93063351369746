.form-floating {
  position: relative;

  &::before:not(.form-control:disabled) {
    position: absolute;
    top: var(--input-border-width);
    left: var(--input-border-width);
    width: calc(100% - (calc(calc(0.375em + 0.1875rem) + calc(0.75em + 0.375rem))));
    height: var(--form-floating-label-height);
    content: "";
    background-color: var(--input-bg);
    border-radius: var(--input-border-radius);
  }

  > .form-control,
  > .form-control-plaintext,
  > .form-select {
    //height: $form-floating-height;
    //line-height: $form-floating-line-height;
  }

  > label {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; // allow textareas
    padding: var(--form-floating-padding-y) var(--form-floating-padding-x);
    overflow: hidden;
    color: var(--input-placeholder-color);
    font-size: var(--input-font-size);
    line-height: 1;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: 0 solid transparent; // Required for aligning label's text with the input as it affects inner box model
    transform-origin: 0 0;
    transition: var(--form-floating-transition);
  }

  > .form-control,
  > .form-control-plaintext {
    padding: var(--form-floating-padding-y) var(--form-floating-padding-x);

    &::placeholder {
      color: transparent;
    }

    &:focus,
    &:not(:placeholder-shown) {
      padding-top: var(--form-floating-input-padding-t);
      padding-bottom: var(--form-floating-input-padding-b);
    }

    // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
    &:-webkit-autofill {
      padding-top: var(--form-floating-input-padding-t);
      padding-bottom: var(--form-floating-input-padding-b);
    }
  }

  > .form-select {
    padding-top: var(--form-floating-input-padding-t);
    padding-bottom: var(--form-floating-input-padding-b);
  }

  > .form-control:focus,
  > .form-control:not(:placeholder-shown),
  > .form-control-plaintext,
  > .form-select {
    ~ label {
      opacity: var(--form-floating-label-opacity);
      //top: calc(var(--form-floating-padding-y) * .5);
      transform: var(--form-floating-label-transform);
      font-size: .8em;
    }
  }

  // Duplicated because `:-webkit-autofill` invalidates other selectors when grouped
  > .form-control:-webkit-autofill {
    ~ label {
      opacity: var(--form-floating-label-opacity);
      transform: var(--form-floating-label-transform);
    }
  }

  /*> .form-control-plaintext {
    ~ label {
      //border-width: $input-border-width 0; // Required to properly position label text - as explained above
    }
  }*/

  > .form-control:disabled ~ label {
    color: var(--form-floating-label-disabled-color);
  }
}
