/*TODO update styles - common and theme*/
.slider {
  --slider-control-height: ;
  --slider-control-width: ;
  --slider-control-background-color: ;
  --slider-control-border-radius: 0;
  --slider-control-color: ;
  --slider-control-font-size: ;
  --slider-control-hover-background-color: ;
  --slider-control-hover-color: ;
  position: relative;
}

.slider-controls {
  --slider-control-height: ;
  --slider-control-width: ;
  --slider-control-border-radius: 0;
  --slider-control-background-color: ;
  --slider-control-color: ;
  --slider-control-active-bg-color: ;
  --slider-control-active-color: var(--slider-control-color);
  --slider-control-hover-bg-color: ;
  --slider-control-hover-color: var(--slider-control-color);
  --slider-control-disabled-opacity: .6;
  --slider-control-font-size: 1rem;
  display: flex;
  align-items: center;
  gap: 2px;

  @media (min-width: 1024px) {
    gap: 4px;
  }
}

.slider-nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--slider-control-height);
  width: var(--slider-control-width);
  background-color: var(--slider-control-background-color);
  border-radius: var(--slider-control-border-radius);
  color: var(--slider-control-color);
  font-size: var(--slider-control-font-size);
  cursor: pointer;
  transition: background-color .3s ease, color .3s ease;

  &:hover {
    background-color: var(--slider-control-hover-bg-color);
    color: var(--slider-control-hover-color);
  }

  &:active {
    background-color: var(--slider-control-active-bg-color);
    color: var(--slider-control-active-color);
  }

  &.swiper-button-disabled {
    opacity: var(--slider-control-disabled-opacity);
    cursor: default;
  }
}

.slider-nav-btn--prev {
  transform: rotate(180deg);
}

.slider-pagination {
  width: auto;
  padding: 0 12px;
  font-size: 12px;
  font-weight: 500;

  @media (min-width: 768px) {
    font-size: 16px;
  }
}

.swiper-pagination-bullet {
  height: 6px;
  width: 6px;
  margin: 0 5px !important;
  background-color: var(--c-gray-light);
  transition: color .3s ease;

  @media (min-width: 1024px) {
    height: 8px;
    width: 8px;
    margin: 0 6px !important;
  }
}

.swiper-pagination-bullet-active {
  //TODO primary color in dependence of bg color
  position: relative;
  color: var(--c-primary);
  background-color: currentColor;

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 12px;
    width: 12px;
    background-color: transparent;
    border: 1px solid currentColor;
    border-radius: 50%;
  }

  @media (min-width: 1024px) {
    &:after {
      height: 16px;
      width: 16px;
    }
  }
}

.slider-pagination--white {
  .swiper-pagination-bullet-active {
    color: var(--c-white);
  }
}

// Full height fix
.swiper-slide--fullheight {
  height: auto;
}

/**
Theme styles
 */
.slider-controls--default {
  --slider-control-height: 38px;
  --slider-control-width: 38px;
  --slider-control-border-radius: 2px;
  --slider-control-background-color: var(--c-text);
  --slider-control-color: var(--c-white);
  --slider-control-active-bg-color: var(--c-red);
  --slider-control-hover-bg-color: var(--c-red-secondary);
  --slider-control-font-size: 12px;

  // animation
  .icon {
    transform: translateX(-2px);
    transition: transform var(--transition-duration) var(--transition-timing-function);
  }

  .slider-nav-btn:hover .icon {
    transform: translateX(2px);
  }

  @media (min-width: 1200px) {
    --slider-control-height: 50px;
    --slider-control-width: 50px;
    --slider-control-font-size: 14px;
  }
}

/*TODO check classname; rename to '.slider--default'*/
.slider--default-theme {
  --slider-control-height: 50px;
  --slider-control-width: 50px;
  --slider-control-background-color: transparent;
  --slider-control-color: var(--c-text);
  --slider-control-font-size: 28px;
}

.slider--default {
  .swiper-slide {
    width: 240px;
  }

  @media (min-width: 768px) {
    .swiper-slide {
      width: 320px;
    }
  }

  @media (min-width: 992px) {
    .swiper-slide {
      width: 410px;
    }
  }
}



// Clients slider
.clients-slider {
  padding: 1rem 0;

  .swiper-wrapper {
    align-items: center;
    transition-timing-function: linear;
  }

  .swiper-slide {
    width: 120px;
    padding: 0 10px;
    filter: grayscale(1);
    opacity: .5;
    text-align: center;
    transition: opacity .6s ease, filter .6s ease;

    &:hover {
      filter: grayscale(0);
      opacity: 1;
    }

    @media (min-width: 576px) {
      width: 150px;
    }

    @media (min-width: 992px) {
      width: 200px;
    }

    @media (min-width: 1200px) {
      width: 260px;
    }
  }

  @media (min-width: 992px) {
    padding: 2rem 0;
  }
}

.slider__wide-wrapper {
  @media (min-width: 1200px) {
    width: 70vw;
    overflow: hidden;
    margin: -40px;
    padding: 40px;
  }
}
