//
// Check/radio
//
:root {
  --form-check-input-width: 18px;
  --form-check-min-height: 1.5rem;
  --form-check-padding-start: calc(var(--form-check-input-width) + 10px);
  --form-check-margin-bottom: 1rem;

  --form-check-label-color: var(--c-grey, #000);
  --form-check-label-hover-color: var(--c-black, #000);
  --form-check-label-font-size: 14px;
  --form-check-label-line-height: 1.3;
  //$form-check-transition:                   null !default;

  --form-check-input-active-filter: brightness(90%);

  --form-check-input-bg: #fff;
  --form-check-input-border: 1px solid var(--c-line);
  --form-check-input-border-radius: .25em;
  --form-check-radio-border-radius: 50%;
  --form-check-input-focus-border: #BEC1C7;
  --form-check-input-focus-box-shadow: ;
  //
  //$form-check-input-checked-color:          $component-active-color !default;
  --form-check-input-checked-bg-color: #fff;
  --form-check-input-checked-border-color: var(--c-primary);
  //$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/></svg>") !default;
  //$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;
  //
  //$form-check-input-indeterminate-color:          $component-active-color !default;
  //$form-check-input-indeterminate-bg-color:       $component-active-bg !default;
  //$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color !default;
  //$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") !default;
  //
  //$form-check-input-disabled-opacity:        .5 !default;
  //$form-check-label-disabled-opacity:        $form-check-input-disabled-opacity !default;
  //$form-check-btn-check-disabled-opacity:    $btn-disabled-opacity !default;
  //
  //$form-check-inline-margin-end:    1rem !default;

  @media (min-width: 1200px) {
    --form-check-input-width: 20px;
    --form-check-label-font-size: 16px;
  }
}

.form-check {
  display: block;
  min-height: var(--form-check-min-height);
  padding-left: var(--form-check-padding-start);
  margin-bottom: var(--form-check-margin-bottom);

  .form-check-input {
    float: left;
    margin-left: calc(var(--form-check-padding-start) * -1);
    cursor: pointer;
  }
}

/*.form-check-reverse {
  padding-right: $form-check-padding-start;
  padding-left: 0;
  text-align: right;

  .form-check-input {
    float: right;
    margin-right: $form-check-padding-start * -1;
    margin-left: 0;
  }
}*/

.form-check-input {
  --form-check-bg: var(--form-check-input-bg);

  width: var(--form-check-input-width);
  height: var(--form-check-input-width);
  vertical-align: top;
  background-color: var(--form-check-input-bg);
  background-image: var(--form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--form-check-input-border);
  appearance: none;
  print-color-adjust: exact; // Keep themed appearance for print
  //@include transition($form-check-transition);

  &[type="checkbox"] {
    border-radius: var(--form-check-input-border-radius);
  }

  &[type="radio"] {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: var(--form-check-radio-border-radius);
  }

  &:active {
    filter: var(--form-check-input-active-filter);
  }

  &:focus {
    border-color: var(--form-check-input-focus-border);
    outline: 0;
    box-shadow: var(--form-check-input-focus-box-shadow);
  }

  &:checked {
    background-color: var(--form-check-input-bg) !important;
    border-color: var(--form-check-input-checked-border-color) !important;

    &[type="checkbox"] {
      --form-check-input-bg: var(--c-primary);
      --form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    }

    &[type="radio"] {
      --form-check-bg-image: url("data:image/svg+xml,%3csvg width='10' height='10' viewBox='0 0 10 10' fill='%23E84343' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='%23E84343'/%3e%3c/svg%3e");
      background-image: url("data:image/svg+xml,%3csvg width='10' height='10' viewBox='0 0 10 10' fill='currentColor' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='5' cy='5' r='5' fill='currentColor'/%3e%3c/svg%3e");
    }
  }

  //&[type="checkbox"]:indeterminate {
  //  background-color: #0d6efd;
  //  border-color: #0d6efd;
  //  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
  //}

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      cursor: default;
      opacity: 0.5;
    }
  }
}

.form-check-label {
  color: var(--form-check-label-color);
  font-size: var(--form-check-label-font-size);
  cursor: pointer;
}

//
// Switch
//

//.form-switch {
//  padding-left: $form-switch-padding-start;
//
//  .form-check-input {
//    --form-switch-bg: #{escape-svg($form-switch-bg-image)};
//
//    width: $form-switch-width;
//    margin-left: $form-switch-padding-start * -1;
//    background-image: var(--form-switch-bg);
//    background-position: left center;
//    @include border-radius($form-switch-border-radius);
//    @include transition($form-switch-transition);
//
//    &:focus {
//      --form-switch-bg: #{escape-svg($form-switch-focus-bg-image)};
//    }
//
//    &:checked {
//      background-position: $form-switch-checked-bg-position;
//
//      @if $enable-gradients {
//        --form-switch-bg: #{escape-svg($form-switch-checked-bg-image)}, var(--gradient);
//      } @else {
//        --form-switch-bg: #{escape-svg($form-switch-checked-bg-image)};
//      }
//    }
//  }
//
//  &.form-check-reverse {
//    padding-right: $form-switch-padding-start;
//    padding-left: 0;
//
//    .form-check-input {
//      margin-right: $form-switch-padding-start * -1;
//      margin-left: 0;
//    }
//  }
//}

//.form-check-inline {
//  display: inline-block;
//  margin-right: $form-check-inline-margin-end;
//}

//.btn-check {
//  position: absolute;
//  clip: rect(0, 0, 0, 0);
//  pointer-events: none;
//
//  &[disabled],
//  &:disabled {
//    + .btn {
//      pointer-events: none;
//      filter: none;
//      opacity: $form-check-btn-check-disabled-opacity;
//    }
//  }
//}
