/**
TODO:
- .modal-fullscreen
 */

// stylelint-disable function-disallowed-list

// .modal-open      - body class for killing the scroll
// .modal           - container to scroll within
// .modal-dialog    - positioning shell for the actual modal
// .modal-content   - actual modal w/ bg and corners and stuff


// Container that the modal scrolls within
.modal {
  // scss-docs-start modal-css-vars
  --modal-zindex: var(--zi-modal, 1055);
  --modal-width: 500px;
  --modal-padding: 1rem;
  --modal-margin: .5rem;
  --modal-color: ;
  --modal-bg: #fff;
  --modal-border-color: transparent;
  --modal-border-width: 0;
  --modal-border-radius: ;
  --modal-box-shadow: ;
  //--modal-inner-border-radius: #{$modal-content-inner-border-radius};
  --modal-header-padding-x: ;
  --modal-header-padding-y: ;
  //--modal-header-padding: #{$modal-header-padding}; // Todo in v6: Split this padding into x and y
  //--modal-header-border-color: #{$modal-header-border-color};
  //--modal-header-border-width: #{$modal-header-border-width};
  --modal-title-font-size: 1rem;
  --modal-title-line-height: ;
  //--modal-footer-gap: #{$modal-footer-margin-between};
  //--modal-footer-bg: #{$modal-footer-bg};
  //--modal-footer-border-color: #{$modal-footer-border-color};
  //--modal-footer-border-width: #{$modal-footer-border-width};
  // scss-docs-end modal-css-vars

  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // We deliberately don't use `-webkit-overflow-scrolling: touch;` due to a
  // gnarly iOS Safari bug: https://bugs.webkit.org/show_bug.cgi?id=158342
  // See also https://github.com/twbs/bootstrap/issues/17695
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--modal-margin);
  // allow clicks to pass through for custom click handling to close modal
  pointer-events: none;

  // When fading in the modal, animate it to slide down
  .modal.fade & {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  }

  .modal.show & {
    transform: none;
  }

  // When trying to close, animate focus to scale
  .modal.modal-static & {
    transform: scale(1.02);
  }
}

/*.modal-dialog-scrollable {
  height: calc(100% - var(--modal-margin) * 2);

  .modal-content {
    max-height: 100%;
    overflow: hidden;
  }

  .modal-body {
    overflow-y: auto;
  }
}*/

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--modal-margin) * 2);
}

// Actual modal
.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  color: var(--modal-color);
  pointer-events: auto;
  background-color: var(--modal-bg);
  background-clip: padding-box;
  border: var(--modal-border-width) solid var(--modal-border-color);
  border-radius: var(--modal-border-radius);
  box-shadow: var(--modal-box-shadow);
  // Remove focus outline from opened modal
  outline: 0;
  overflow: hidden;
}

// Modal background
.modal-backdrop {
  // scss-docs-start modal-backdrop-css-vars
  --backdrop-zindex: var(--zi-modal-backdrop, 1050);
  --backdrop-bg: rgba(23, 23, 23, .5);
  //--backdrop-opacity: .5;
  // scss-docs-end modal-backdrop-css-vars

  @include overlay-backdrop(var(--backdrop-zindex), var(--backdrop-bg), var(--backdrop-opacity));
}

// Modal header
// Top section of the modal w/ title and dismiss
/*.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between; // Put modal header elements (title and dismiss) on opposite ends
  padding: var(--modal-header-padding);
  border-bottom: var(--modal-header-border-width) solid var(--modal-header-border-color);
  //@include border-top-radius(var(--modal-inner-border-radius));

  .btn-close {
    padding: calc(var(--modal-header-padding-y) * .5) calc(var(--modal-header-padding-x) * .5);
    margin: calc(-.5 * var(--modal-header-padding-y)) calc(-.5 * var(--modal-header-padding-x)) calc(-.5 * var(--modal-header-padding-y)) auto;
  }
}*/

// Title text within header
.modal-title {
  margin-bottom: 0;
  font-size: var(--modal-title-font-size);
  line-height: var(--modal-title-line-height);
  font-weight: var(--modal-title-font-weight);

  text-align: center;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when there should be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: var(--modal-padding);
}

// Footer (for actions)
/*.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center; // vertically center
  justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items
  padding: calc(var(--modal-padding) - var(--modal-footer-gap) * .5);
  background-color: var(--modal-footer-bg);
  border-top: var(--modal-footer-border-width) solid var(--modal-footer-border-color);
  //@include border-bottom-radius(var(--modal-inner-border-radius));

  // Place margin between footer elements
  // This solution is far from ideal because of the universal selector usage,
  // but is needed to fix https://github.com/twbs/bootstrap/issues/24800
  > * {
    margin: calc(var(--modal-footer-gap) * .5); // Todo in v6: replace with gap on parent class
  }
}*/

// Scale up the modal
@media (min-width: 576px) {
  .modal {
    --modal-margin: 1.75rem;
  }

  .modal-dialog {
    max-width: var(--modal-width);
    margin-right: auto;
    margin-left: auto;
  }

  .modal-sm {
    --modal-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    --modal-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    --modal-width: 1140px;
  }
}

// scss-docs-start modal-fullscreen-loop
@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
  $postfix: if($infix != "", $infix + "-down", "");

  @include media-breakpoint-down($breakpoint) {
    .modal-fullscreen#{$postfix} {
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;

      .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
      }

      .modal-header,
      .modal-footer {
        border-radius: 0;
      }

      .modal-body {
        overflow-y: auto;
      }
    }
  }
}

// scss-docs-end modal-fullscreen-loop

/**
Theme styles
 */
.modal--default {
  --modal-width: 570px;
  --modal-padding: 30px 20px;
  --modal-border-radius: 0;
  --modal-title-font-size: 20px;
  --modal-title-line-height: 1.1;
  --modal-title-font-weight: 800;

  .modal-title {
    color: var(--c-black);
    text-align: left;
  }

  .tariff-period-tabs {
    margin: 2rem 0;
  }

  @media (min-width: 992px) {
    --modal-padding: 50px 80px;
    --modal-title-font-size: 36px;

  }
}

.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;

  @media (min-width: 992px) {
    top: 20px;
    right: 20px;
  }
}

.modal--usage {
  .modal-dialog {
    height: auto !important;
  }

  .modal-header {
    position: relative;
    height: 240px;
    flex-shrink: 0;
    overflow: hidden;
    margin-bottom: 2.5rem;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
      opacity: 0.5;
    }
  }

  .modal-header__content {
    position: relative;
    z-index: 3;
  }

  .modal-header__image {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }


  @media (min-width: 768px) {
    margin-bottom: 3.5rem;

    .modal-header {
      height: 360px;
    }
  }

  @media (min-width: 1200px) {
    margin-bottom: 5rem;

    .modal-header {
      height: 460px;
    }
  }
}

.modal--success {
  --modal-width: 480px;
}
