.partners-slider {
  .card {
    min-height: 380px;

    @media (min-width: 992px) {
      min-height: 415px;
    }
  }
}

.map-wrapper {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  overflow: hidden;

  @media (min-width: 992px) {
    padding: 60px 0;
    margin-bottom: 0;
  }
}

.map-container {
  height: 350px;

  @media (min-width: 992px) {
    height: 560px;

    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: -300px;
    right: -300px;
    z-index: -1;
    margin-left: 300px;
  }
}

.map-contacts-block {
  padding: 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 10px 40px 0 #00000017;

  @media (min-width: 992px) {
    max-width: 460px;
    padding: 40px 30px;
  }
}
