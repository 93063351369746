.search {
  position: relative;
}

.search-group {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (min-width: 992px) {
    gap: 30px;
  }
}

.search-input {
  flex-grow: 1;
  //width: 100%;
  margin-right: -64px;
  padding: 15px 60px 15px 0;
  background-color: #fff;
  color: var(--c-text);
  font-size: 1rem;
  line-height: 1.25;
  font-weight: 700;
  border-bottom: 2px solid #E3E3E3;

  &:focus {
    outline: none;
  }

  &:hover,
  &:focus {
    border-color: var(--c-black);
  }

  &::placeholder {
    color: var(--c-grey);
  }

  @media (min-width: 992px) {
    padding: 20px 60px 20px 0;
    font-size: 1.5rem;
  }

  @media (min-width: 1200px) {
    font-size: 2rem;
  }
}

.search-btn {
  flex-shrink: 0;
  width: 22px;
  height: 22px;
  padding: 0;
  background-color: transparent;
  color: #BABABA;
  //position: absolute;
  //z-index: 1;
  cursor: pointer;

  .icon {
    width: 100%;
    height: 100%;
  }

  @media (min-width: 992px) {
    width: 2rem;
    height: 2rem;
  }
}
