@import "forms/floating-labels";
@import "forms/form-check";
@import "forms/form-control";

/**
Theme styles
 */
.form--default {
  --input-padding-y: 14px;
  --input-padding-x: 0px;
  --input-bg: transparent;
  --input-focus-bg: var(--input-bg);
  --input-border-color: #C9C9C9;
  --input-border-width: 0;
  --input-color: var(--c-text, #000);
  --input-focus-color: var(--input-color);
  --input-font-size: 13px;
  --input-line-height: 1.25;
  --input-placeholder-color: var(--c-grey);

  // scss-docs-start form-floating-variables
  --form-floating-height: calc(3.5rem + calc(var(--input-border-width *2)));
  --form-floating-line-height: 1.25;
  --form-floating-padding-x: var(--input-padding-x);
  --form-floating-padding-y: 13px;
  --form-floating-input-padding-t: 21px;
  --form-floating-input-padding-b: 9px;
  --form-floating-label-height: 1.875em;
  --form-floating-label-opacity: 1;
  --form-floating-label-translate-y: calc(var(--form-floating-padding-y) * -.5);
  --form-floating-label-translate-x: ;
  --form-floating-label-transform: translateY(var(--form-floating-label-translate-y));
  --form-floating-label-disabled-color: ;
  --form-floating-transition: opacity .1s ease-in-out, font-size .1s ease-in-out, transform .1s ease-in-out;
  // scss-docs-end form-floating-variables

  @media (min-width: 992px) {
    --input-padding-y: 18px;
    --input-font-size: 1rem;
    //
    --form-floating-padding-y: 20px;
    --form-floating-input-padding-t: 30px;
    --form-floating-input-padding-b: 10px;
  }

  .form-control {
    border-bottom: 1px solid var(--input-border-color);
  }
}

.form-policy {
  margin: 0;
  color: var(--c-grey);
  font-size: 11px;
  line-height: 14px;
  font-weight: 600;

  @media (min-width: 992px) {
    font-size: 13px;
    line-height: 16px;
  }
}

.form-layout-1 {
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 40px;
  margin-bottom: 40px;

  .form-image {
    position: absolute;
    right: -1.5rem;
    bottom: -2rem;
    max-width: 115px;
  }

  @media (min-width: 768px) {
    .form-image {
      max-width: 170px;
    }
  }

  @media (min-width: 992px) {
    margin-top: 80px;
    margin-bottom: 80px;

    .link {
      font-size: 24px;
    }

    .form-image {
      max-width: 230px;
    }
  }

  @media (min-width: 1200px) {
    margin-bottom: 160px;
  }
}
