.section--company {
  .section__bg {
    height: 81%;
    margin-top: 110px;
  }

  @media (min-width: 992px) {
    .section__bg {
      height: 585px;
      margin-top: 0;
    }
  }
}
