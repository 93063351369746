// TODO move to transitions.scss
// TODO move to utilities/transitions?

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsed {
  .dropdown-caret {
    transform: rotate(180deg);
  }
}
