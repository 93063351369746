/*TODO base link styles*/
.link {
  --link-color: ;
  --link-hover-color: ;
  --link-active-color: ;
  --link-font-size: 14px;
  --link-line-height: 1.3;

  position: relative;
  background-color: transparent;
  color: var(--link-color);
  font-size: var(--link-font-size);
  line-height: var(--link-line-height);
  text-decoration: none !important;
  transition: color var(--transition-duration) var(--transition-timing-function);

  @media (min-width: 1200px) {
    --link-font-size: 16px;
  }

  &:hover,
  &:focus {
    color: var(--link-hover-color);
  }

  &:active {
    color: var(--link-active-color);
  }
}

.link-icon {
  --link-icon-size: 1rem;
  --link-icon-spacing: .5rem;
  display: inline-flex;
  align-items: center;

  .link__icon {
    width: var(--link-icon-size);
    height: var(--link-icon-size);
    margin-left: var(--link-icon-spacing);
  }

  .icon {
    width: 100%;
    height: 100%;
  }
}

/**
Theme styles
 */
.link-primary {
  --link-color: var(--c-red-secondary);
  --link-hover-color: var(--c-black);
  --link-active-color: var(--c-text);
}

.link-view-all {
  --link-icon-spacing: .5rem;

  @media (min-width: 992px) {
    --link-icon-size: 18px;
    --link-icon-spacing: 10px;
    --link-font-size: 18px;
  }

  .link__icon {
    position: relative;
    width: var(--link-icon-size);
    height: var(--link-icon-size);
    margin-left: var(--link-icon-spacing);
    overflow: hidden;
  }

  // animation
  .icon {
    position: absolute;
    margin-left: 0;
    transition: transform .4s var(--transition-timing-function);

    &:last-of-type {
      transform: translate(-120%, 120%);
    }
  }

  &:hover,
  &:focus {
    .icon:first-of-type {
      transform: translate(120%, -120%);
    }

    .icon:last-of-type {
      transform: translate(0, 0);
    }
  }
}

.link-white {
  --link-color: var(--c-white);
  --link-hover-color: var(--c-white);
  --link-active-color: var(--c-white);
}

/**

 */
.link-dark {
  color: var(--c-black);

  &:hover,
  &:focus {
    color: var(--c-primary);
  }
}

/*TODO transition*/
/*link more*/
.link-secondary {
  color: var(--c-black);

  &:hover,
  &:focus {
    color: var(--c-primary);
  }
}


.link-sm {
  font-size: 12px;

  @media (min-width: 1200px) {
    font-size: 15px;
  }
}

.link-download {
  .icon {
    width: 28px;
    height: 28px;
    margin-right: 10px;
  }
}

.file-download__info {
  margin-top: .5rem;
  color: var(--c-grey);
  font-size: 13px;
}
