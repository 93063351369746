.advantage-item__icon {

  @media (min-width: 1200px) {
    width: 70px;
    height: 70px;
  }
}

.advantage-item__title {
  max-width: 90%;
  line-height: 1.3;
  font-weight: 600;
}

