/**
https://getbootstrap.com/docs/5.3/components/buttons/
 */

//
// Base styles
//
.btn {
  // scss-docs-start btn-css-vars
  --btn-padding-y: 12px;
  --btn-padding-x: 20px;
  --btn-font-family: ; // todo body font-family var
  --btn-font-size: 1rem;
  --btn-font-weight: 400;
  --btn-line-height: 1.5;
  --btn-color: var(--body-color, #000);
  --btn-bg: transparent;
  --btn-border-width: 1px;
  --btn-border-color: transparent;
  --btn-border-radius: ;
  --btn-hover-border-color: transparent;
  --btn-box-shadow: ;
  --btn-disabled-opacity: 0.65;
  --btn-focus-box-shadow: ;
  --btn-transition-property: background-color, color, border-color, box-shadow;
  --btn-transition-duration: .15s;
  --btn-transition-timing-function: cubic-bezier(0.52, 0.27, 0.34, 1);
  // scss-docs-end btn-css-vars

  display: inline-block;
  padding: var(--btn-padding-y) var(--btn-padding-x);
  font-family: var(--btn-font-family);
  font-size: var(--btn-font-size);
  font-weight: var(--btn-font-weight);
  line-height: var(--btn-line-height);
  color: var(--btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: var(--btn-border-width) solid var(--btn-border-color);
  border-radius: var(--btn-border-radius);
  background-color: var(--btn-bg);
  box-shadow: var(--btn-box-shadow);
  transition-duration: var(--btn-transition-duration);
  transition-property: var(--btn-transition-property);
  transition-timing-function: var(--btn-transition-timing-function);

  @media (prefers-reduced-motion: reduce) {
    transition: none;
  }

  &:hover {
    color: var(--btn-hover-color);
    text-decoration: none;
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
  }

  &:focus {
    color: var(--btn-hover-color);
    background-color: var(--btn-hover-bg);
    border-color: var(--btn-hover-border-color);
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    // Avoid using mixin so we can pass custom focus shadow properly
    box-shadow: var(--btn-focus-box-shadow, var(--btn-box-shadow));
  }

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border-color, var(--btn-border-color));
    box-shadow: var(--btn-active-shadow);

    &:focus-visible {
      // Avoid using mixin so we can pass custom focus shadow properly
      box-shadow: var(--btn-active-shadow, var(--btn-focus-box-shadow));
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: var(--btn-disabled-color);
    pointer-events: none;
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border-color, var(--btn-border-color));
    opacity: var(--btn-disabled-opacity);
    box-shadow: none;
  }
}

/**
Theme styles
 */
.btn {
  --btn-padding-y: 12px;
  --btn-padding-x: 20px;
  --btn-font-size: 13px;
  --btn-line-height: 1;
  --btn-font-weight: 600;
  --btn-border-radius: 2px;

  @media (min-width: 1200px) {
    --btn-padding-y: 13px;
    --btn-padding-x: 40px;
    --btn-font-size: 18px;
  }
}

.btn-primary {
  --btn-color: #fff;
  --btn-bg: var(--c-secondary);
  --btn-hover-color: #fff;
  --btn-hover-bg: var(--c-primary);
  //--btn-active-color: ;
  --btn-active-color: #fff;
  --btn-active-bg: var(--c-secondary);
}

.btn-icon {
  --btn-padding-x: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;

  @media (min-width: 1200px) {
    --btn-padding-x: 48px;
    column-gap: 12px;

    .icon {
      width: 22px;
      height: 22px;
    }
  }
}

.btn-outline {
  --btn-padding-x: 1.5rem;
  --btn-color: var(--c-text);
  --btn-bg: transparent;
  --btn-border-width: 1px;
  --btn-border-color: #DFDFDF; // todo color var
  --btn-hover-color: var(--c-red-secondary);
  --btn-hover-bg: var(--c-white);
  --btn-hover-border-color: var(--btn-border-color);
  //--btn-active-color: ;
  --btn-active-color: var(--c-red);
  --btn-active-bg: #DFDFDF; // todo color var
  --btn-active-border-color: var(--btn-border-color);

  .icon {
    width: 14px;
    height: 14px;
  }
}

// Sizes
.btn-lg {
  --btn-padding-x: 60px;
  --btn-padding-y: 15px;
  --btn-font-size: 14px;

  @media (min-width: 1200px) {
    --btn-padding-y: 19px;
    --btn-padding-x: 40px;
    --btn-font-size: 18px;
  }
}

.btn-sm {
  --btn-padding-y: 12px;
  --btn-padding-x: 20px;
  --btn-font-size: 13px;

  @media (min-width: 992px) {
    --btn-font-size: 16px;
  }
}

.btn-close {
  //width: 22px;
  //height: 22px;
  background-color: transparent;
  color: #BABABA;
  cursor: pointer;
  padding: 5px;

  .icon {
    //width: 100%;
    //height: 100%;
    width: 22px;
    height: 22px;
  }

  @media (min-width: 992px) {
    .icon {
      width: 26px;
      height: 26px;
    }
  }
}

.btn-close--circle {
  .icon {
    width: 2rem;
    height: 2rem;
    padding: 9px;
    background-color: #fff;
    border-radius: 50%;
  }

  @media (min-width: 992px) {
    .icon {
      width: 44px;
      height: 44px;
    }
  }
}
