/**
Bootstrap text utilities
+ Text alignment
+ Text wrapping and overflow
+ Word break
+ Text transform
- Font size
- Monospace ( // todo ? )
+ Reset color
+ Text decoration
 */
// Font size
.fs-125, .fs-20 {
  font-size: 1.25em; //  font-size: 20px;
}

// Font style
.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

// Font weight
.fw-lighter {
  font-weight: lighter !important;
}

.fw-300,
.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-500,
.fw-medium {
  font-weight: 500 !important;
}

.fw-600,
.fw-semibold {
  font-weight: 600 !important;
}

.fw-700,
.fw-bold {
  font-weight: 700 !important;
}

.fw-800,
.fw-bolder {
  font-weight: 800 !important;
}

// Line height
.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

@media (min-width: 576px) {
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-start {
    text-align: left !important;
  }
  .text-xl-end {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1400px) {
  .text-xxl-start {
    text-align: left !important;
  }
  .text-xxl-end {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}
