html {
  scroll-behavior: smooth;
}

body {
  color: var(--c-text);
  font-family: Mulish, sans-serif;
  font-size: 14px;
  line-height: 1.5;

  @media (min-width: 992px) {
    font-size: 16px;
  }

  @media (min-width: 1200px) {
    font-size: 18px;
  }
}

img,
svg {
  max-width: 100%;
  height: auto;
}

.img-cover {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.i {
  display: inline-block;
}

hr,
.hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid #EFF3F8;

  //margin: 0 -15px;
  //border-top: 1px solid #EFF3F8;
}

a:hover,
a:focus {
  color: var(--c-blue);
}
