@import 'variables/grid';
@import 'variables/spacing';

:root {
  /**
  Colors
   */
  // theme colors
  --body-color: #4c4c4c;
  --c-text: #4c4c4c;
  --c-black: #111111;
  --c-grey: #8E8E8E;
  --c-grey-light: #f1f1f1;
  --c-primary: #FF2929;
  --c-red: #FF2929;
  --c-secondary: #EA393D;
  --c-red-secondary: #EA393D;
  // additional colors
  --c-white: #FFFFFF;
  --c-grey-94: #F0F0F0;
  --c-grey-95: #F4F4F4;
  --c-grey-f4: #F4F4F4;
  --c-grey-f5: #f5f5f5;
  // todo var for #dfdfdf

  // Box-shadow default
  --box-shadow: 0 10px 40px 0 rgba(0, 0, 0, .09);

  // Transition
  --transition-duration: .25s;
  --transition-timing-function: cubic-bezier(0.45, 0.13, 0.31, 1);

  /**
  Z-index variables
   */
  --zi-dropdown: 200;
  --zi-dropdown-backdrop: 199;
  --zi-header: 500;
  --zi-modal: 600;
  --zi-modal-backdrop: 599;
  --zi-mobile-menu: 400;
  --zi-offcanvas: 400;
  --zi-offcanvas-backdrop: 399;

  @media (min-width: 1200px) {
    //--grid-gutter-x: 24px;
  }
}
