// TODO https://getbootstrap.com/docs/5.3/content/typography/

/**
Headings
 */
h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;

  /**
  theme styles
   */
  color: var(--c-black);
  font-weight: 800;
  font-family: Mulish, sans-serif;
  line-height: 1.25;
}

h1, .h1 {
  font-size: 26px;
  margin-bottom: 1.25rem;

  @media (min-width: 1200px) {
    font-size: 48px;
    margin-bottom: 3.5rem;
  }
}

h2, .h2 {
  font-size: 20px;
  margin-bottom: 1.25rem;

  @media (min-width: 992px) {
    font-size: 30px;
    margin-bottom: 2rem;
  }

  @media (min-width: 1200px) {
    font-size: 40px;
    margin-bottom: 2.5rem;
  }
}

h3, .h3 {
  font-size: 18px;
  margin-bottom: 1rem;

  @media (min-width: 992px) {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  @media (min-width: 1200px) {
    font-size: 36px;
    margin-bottom: 2.5rem;
  }
}

h4, .h4 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 1.25em;

  @media (min-width: 1200px) {
    font-size: 30px;
    margin-bottom: 2rem;
  }
}

// Vertical rule
.vr {
  display: inline-block;
  align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentcolor;
}

blockquote {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 0 0 1rem;
  background-color: #f4f4f4;

  p {
    margin-top: 0;
    margin-bottom: 2rem;
    font-style: italic;
    line-height: 1.2;
  }

  cite {
    color: var(--c-grey);
    font-size: 14px;
    text-align: right;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 40px;
    left: 30px;
    background-image: url("data:image/svg+xml,%3Csvg width='53' height='36' viewBox='0 0 53 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.4213 23.7949C5.87751 23.7949 0.583369 18.4412 0.583369 11.8974C0.583369 5.35363 5.937 0 12.4808 0C19.0246 0 24.3782 5.35363 24.3782 11.8974C24.3782 25.0441 13.73 35.6923 0.583369 35.6923C0.583369 35.6923 8.37608 32.7773 12.4213 23.7949ZM28.5694 11.8974C28.5694 5.35363 33.923 0 40.4668 0C47.0106 0 52.3643 5.35363 52.3643 11.8974C52.3643 25.0441 41.7161 35.6923 28.5694 35.6923C28.5694 35.6923 36.3621 32.7773 40.4073 23.7949C33.8635 23.7949 28.5694 18.4408 28.5694 11.8974Z' fill='%23CFCFCF'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
  }

  @media (min-width: 992px) {
    padding: 40px 30px 40px 110px;
    margin-bottom: 1.25rem;

    p {
      font-size: 18px;
    }

    &:after {
      width: 58px;
      height: 58px;
    }
  }
}

.blockquote--card {
  background-color: transparent;
  padding: 0;

  .blockquote__text {
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media (min-width: 992px) {
    padding-left: 80px;

    &:after {
      top: 0;
      left: 0;
    }
  }
}
